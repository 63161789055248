// Generated by Framer (c60b0a0)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./qIY4fCEds-0.js";
import ButtonsIconButton from "./WAIGe2kKG";
const ButtonsIconButtonFonts = getFonts(ButtonsIconButton);

const cycleOrder = ["DjUYrlTv4"];

const serializationHash = "framer-iP1PZ"

const variantClassNames = {DjUYrlTv4: "framer-v-1gs7qt0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "DjUYrlTv4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.nav {...restProps} {...gestureHandlers} aria-label={"Social sharing"} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1gs7qt0", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"DjUYrlTv4"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider height={28} y={((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 200) - 0) - 28) / 2)))}><motion.div className={"framer-d99e2j-container"} layoutDependency={layoutDependency} layoutId={"SGXYU52qC-container"}><ButtonsIconButton height={"100%"} id={"SGXYU52qC"} layoutId={"SGXYU52qC"} pZI79J6Us={"https://www.facebook.com/pulsm3dia"} style={{height: "100%"}} v4h3sEhwi={"Rounded"} variant={"Hta26CLGn"} width={"100%"} wW6uA75t4={"facebook"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider height={28} y={((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 200) - 0) - 28) / 2)))}><motion.div className={"framer-syyyy2-container"} layoutDependency={layoutDependency} layoutId={"pH8Kqs_rA-container"}><ButtonsIconButton height={"100%"} id={"pH8Kqs_rA"} layoutId={"pH8Kqs_rA"} pZI79J6Us={"https://www.linkedin.com/company/pulsmedia/"} style={{height: "100%"}} v4h3sEhwi={"Rounded"} variant={"Hta26CLGn"} width={"100%"} wW6uA75t4={"linkedin"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider height={28} y={((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 200) - 0) - 28) / 2)))}><motion.div className={"framer-1ygzngq-container"} layoutDependency={layoutDependency} layoutId={"z2DHFTfHU-container"}><ButtonsIconButton height={"100%"} id={"z2DHFTfHU"} layoutId={"z2DHFTfHU"} pZI79J6Us={"mailto: hallo@pulsmedia.is"} style={{height: "100%"}} v4h3sEhwi={"Rounded"} variant={"Hta26CLGn"} width={"100%"} wW6uA75t4={"email"}/></motion.div></ComponentViewportProvider></motion.nav></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iP1PZ.framer-1yvhgup, .framer-iP1PZ .framer-1yvhgup { display: block; }", ".framer-iP1PZ.framer-1gs7qt0 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 248px; }", ".framer-iP1PZ .framer-d99e2j-container, .framer-iP1PZ .framer-syyyy2-container, .framer-iP1PZ .framer-1ygzngq-container { flex: none; height: 28px; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-iP1PZ.framer-1gs7qt0 { gap: 0px; } .framer-iP1PZ.framer-1gs7qt0 > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-iP1PZ.framer-1gs7qt0 > :first-child { margin-left: 0px; } .framer-iP1PZ.framer-1gs7qt0 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 248
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqIY4fCEds: React.ComponentType<Props> = withCSS(Component, css, "framer-iP1PZ") as typeof Component;
export default FramerqIY4fCEds;

FramerqIY4fCEds.displayName = "Buttons/Share buttons";

FramerqIY4fCEds.defaultProps = {height: 28, width: 248};

addFonts(FramerqIY4fCEds, [{explicitInter: true, fonts: []}, ...ButtonsIconButtonFonts], {supportsExplicitInterCodegen: true})